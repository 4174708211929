import { useState, useEffect } from "react"

export const useIsChartLoading = (isLoading, chart_data) => {
    const [isChartLoading, setIsChartLoading] = useState(true)
    useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            setIsChartLoading(isLoading)
        }
    }, [isLoading, chart_data])
    return [isChartLoading]
}

export const useChartTitle = (title, view_mode) => {
    const [chartTitle, setChartTitle] = useState(title)
    useEffect(() => {
        if (view_mode === 'daily') {
            setChartTitle('Daily Cash Drops')
        } else if (view_mode === 'weekly') {
            setChartTitle('Weekly Cash Drops')
        } else if (view_mode ===  'monthly') {
            setChartTitle('Monthly Cash Drops')
        }
    }, [view_mode])
    return [chartTitle]
}

export const useChartData = (chart_data, view_mode, chartTitle) => {
    const [chartData, setChartData] = useState([])
    // distinct products doesn't seem to be used for anything
    const [distinctProducts, setDistinctProducts] = useState([]);
    useEffect(() => {
        if (! chart_data || ! Object.keys(chart_data).length) {
            return;
        }
        console.assert(['daily', 'weekly', 'monthly'].includes(view_mode));
        console.assert(Object.hasOwn(chart_data, view_mode))

        setChartData(chart_data[view_mode])
        setDistinctProducts(chart_data.distinct_products)
    }, [chart_data, view_mode, chartTitle]);
    return [chartData, distinctProducts]
}

export const useChartXLabels = (chartData) => {
    const [chartXLabels, setChartXLabels] = useState([]);
    useEffect(() => {
        const labels = chartData.map(item => item.date.split(' ')[0])
        setChartXLabels(labels)
    }, [chartData])
    return [chartXLabels]
}

export const useDataSeries = (chartData) => {
    const [dataSeries, setDataSeries] = useState([]);
    useEffect(() => {
        const newDataSeries = [];
        newDataSeries.push({
            data: chartData.map(item => { return item.amount; }),
            label: 'Total Cash Drop Amount',
            id: 'Total Cash Drop Amount',
            color: '#ff6600'
        })
        setDataSeries(newDataSeries)
    }, [chartData]);
    return [dataSeries]
}