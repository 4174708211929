import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/login_page/LoginPage';
import CorePage from './pages/core/Core';
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
	from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';


const errorLink = onError(({graphqlErrors, networkError, response}) => {
    // if (graphqlErrors) {
    //     graphqlErrors.map(({message, location, path}) => {
    //         alert(`Graphql error ${message}`);
    //     })
    // }

    // console.log('RESPONSE: ', response)
    if (response) {
        if (response.errors.length > 0) {
            if (response.errors[0].message === 'Unauthorized') {
                console.log('UNAUTH ERROR: ', response.errors[0].path)

                // Logout
                localStorage.removeItem('token')
                window.location.replace('/');
            }
        }
    }

    if (graphqlErrors) {
        graphqlErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });

        // Optionally, modify the response here
        if (response) {
            response.errors = graphqlErrors.map((err) => ({
                ...err,
                message: `Customized error: ${err.message}`,
            }));
        }
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const httpLink = from([
    errorLink,
    new HttpLink({ uri: '/api' }),
]);

// const wsLink = new WebSocketLink({
//     uri: 'ws://localhost:5050/graphql',
//     options: {
//         reconnect: true
//     }
// });

const authLink = setContext((_, { headers }) => {
    // Get the authentication token from localStorage if it exists
    const token = localStorage.getItem('token');
    // console.log('AUTH-TOKEN: ', token)

    // Return the headers to the context, including the token if present
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),

});

function App() {
    const [authToken, setAuthToken] = React.useState( localStorage.getItem('token') );
    // const [authToken, setAuthToken] = React.useState( null );

    return (
        <ApolloProvider client={client}>
            <Router>
                <Routes>
                    {authToken === null ? (
                        <>
                        <Route path="/" element={<LoginPage setAuthToken={setAuthToken} />} />
                        {/* Redirect to login and then bring them back to the page they wanted */}
                        <Route path="*" element={<LoginPage setAuthToken={setAuthToken} returnToPath={true} />} />
                        </>
                    ) : (
                    <>
                        <Route path="/dashboard/*" element={<CorePage setAuthToken={setAuthToken} />} />
                        {/* Deafult Landing Page (When logged in) */}
                        <Route path="*" element={<Navigate to="/dashboard/overview" />} />
                    </>
                    )}
                </Routes>
            </Router>
        </ApolloProvider>
    );
}

export default App;
